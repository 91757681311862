import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  BatchOverridePositionsRequest,
  CreateGroupRequest,
  CreateMenuSectionRequest,
  GecoPosMenu,
  GetMenuSyncOfExternalPOSResponse,
  GetMenuSyncsOfSaleSystemsResponse,
  Group, MenuEvent, MenuEventsFilterRequest,
  MenuSection,
  MenuSyncOfPOS,
  MenuSyncOfSale,
  OverridePosition,
  OverridePositionRequest,
  UpdateMenuSectionRequest,
  UploadImageRequest,
} from 'app/models/menu.model';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { GecoPosCreateOrUpdateOrganizationRequest } from 'app/models/gecoPosCreateOrUpdateOrganizationRequest';
import { PosOrganizationModel } from 'app/reducers/pos.reducer';
import { PageResult } from 'app/models/pageResult.model';

@Injectable({
  providedIn: 'root'
})
export class DishesService {

  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
  }

  public getMenuOrganizationList(baseUrl = `${environment.posBaseUrl}`): Promise<IBaseResponse<PosOrganizationModel[]>> {
    const url = `${baseUrl}/api/front/organization/list`;
    return firstValueFrom(this.http.get<IBaseResponse<PosOrganizationModel[]>>(url, {
      headers: this.headers,
    }));
  }

  public createOrganization(createOrganization: GecoPosCreateOrUpdateOrganizationRequest): Observable<IBaseResponse<PosOrganizationModel>> {
    const url = `${environment.posBaseUrl}/api/front/organization`;
    return this.http.post<IBaseResponse<PosOrganizationModel>>(url, createOrganization, {
      headers: this.headers,
    });
  }

  public updateOrganization(updateOrganization: GecoPosCreateOrUpdateOrganizationRequest): Observable<IBaseResponse<PosOrganizationModel>> {
    const url = `${environment.posBaseUrl}/api/front/organization`;
    return this.http.put<IBaseResponse<PosOrganizationModel>>(url, updateOrganization, {
      headers: this.headers,
    });
  }

  public getNewFullMenuData$(organizationId: string): Observable<IBaseResponse<GecoPosMenu>> {
    const url = `${environment.posBaseUrl}/api/front/menu/full/${organizationId}`;
    return this.http.get<IBaseResponse<GecoPosMenu>>(url, {
      headers: this.headers,
    });
  }

  public getMenuSections$(organizationId: string): Observable<IBaseResponse<MenuSection[]>> {
    const url = `${environment.posBaseUrl}/api/front/menu/section/list/${organizationId}`;
    return this.http.get<IBaseResponse<MenuSection[]>>(url, {
      headers: this.headers,
    });
  }

  public getOverrides$(menuSectionId: string): Observable<IBaseResponse<OverridePosition[]>> {
    const url = `${environment.posBaseUrl}/api/front/override/list/${menuSectionId}`;
    return this.http.get<IBaseResponse<OverridePosition[]>>(url, {
      headers: this.headers,
    });
  }

  public saveOverridePositions$(overridePositions: OverridePositionRequest[], menuSectionIds: string[]): Observable<IBaseResponse<OverridePosition[]>> {
    const batchOverridePositionsRequest: BatchOverridePositionsRequest = {
      overridePositions,
      menuSectionIds,
    }
    const url = `${environment.posBaseUrl}/api/front/override/batch`;
    return this.http.post<IBaseResponse<OverridePosition[]>>(url, batchOverridePositionsRequest, {
      headers: this.headers,
    });
  }

  public uploadImage$(uploadImageRequest: UploadImageRequest): Observable<IBaseResponse<string>> {
    let url = `${environment.posBaseUrl}/api/front/upload`;
    return this.http.post<IBaseResponse<string>>(url, uploadImageRequest, {
      headers: this.headers,
    });
  }

  public updateMenuSection(menuUpdateRequest: UpdateMenuSectionRequest): Promise<IBaseResponse<MenuSection>> {
    const url = `${environment.posBaseUrl}/api/front/menu/section`;
    return firstValueFrom(this.http.put<IBaseResponse<MenuSection>>(url, menuUpdateRequest, {
      headers: this.headers,
    }));
  }

  public createMenuSection(menuCreateRequest: CreateMenuSectionRequest): Promise<IBaseResponse<MenuSection>> {
    const url = `${environment.posBaseUrl}/api/front/menu/section`;
    return firstValueFrom(this.http.post<IBaseResponse<MenuSection>>(url, menuCreateRequest, {
      headers: this.headers,
    }));
  }

  public createGroup(createGroupRequest: CreateGroupRequest): Observable<IBaseResponse<Group>> {
    const url = `${environment.posBaseUrl}/api/front/group`;
    return this.http.post<IBaseResponse<Group>>(url, createGroupRequest, {
      headers: this.headers,
    });
  }

  // HUB REQUEST FOR MENU
  public getMenuSyncForExternalPOS(organizationId: string): Observable<MenuSyncOfPOS | null> {
    return this.http.get<GetMenuSyncOfExternalPOSResponse | null>(
      `${environment.baseUrl}/api/external-systems/gecopossales/menu/last-sync/${organizationId}`)
      .pipe(
        map((response: GetMenuSyncOfExternalPOSResponse | null) => {
          if (!response) {
            return null;
          } else {
            return {
              pos: response.externalPOS,
              lastSyncedAt: response.lastSyncedAt,
              pipeId: response.pipeId
            } as MenuSyncOfPOS;
          }
        })
      );
  }

  public getMenuSyncsForSaleSystems(organizationId: string): Observable<MenuSyncOfSale[]> {
    return this.http.get<GetMenuSyncsOfSaleSystemsResponse>(
      `${environment.baseUrl}/api/external-systems/gecopos/menu/last-syncs/${organizationId}`)
      .pipe(
        map((response: GetMenuSyncsOfSaleSystemsResponse) => {
          if (!response) {
            return [];
          } else {
            const res = Object.keys(response.records).map((menuId) => {
              const syncs = response.records[menuId];
              return syncs.map((sync) =>
                ({
                  service: sync.saleSystem,
                  menuId,
                  lastSyncedAt: sync.lastSyncedAt,
                  pipeId: sync.pipeId
                }) as MenuSyncOfSale);
            });
            return res.flat();
          }
        })
      );
  }

  public async getMenuEventsAsync(filter: MenuEventsFilterRequest)
    : Promise<IBaseResponse<PageResult<MenuEvent>>> {
    const url = `${environment.posBaseUrl}/api/front/menu_events`;
    try {
      return await firstValueFrom(this.http.post<IBaseResponse<PageResult<MenuEvent>>>(
        url,
        filter,
        {
          headers: this.headers
        }));
    }
    catch (exception)
    {
      console.log('exception', exception);
      return exception.error;
    }
  }
}
