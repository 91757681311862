import { createReducer } from '@reduxjs/toolkit';
import { PosOrganizationModel } from 'app/reducers/pos.reducer';
import { Currency } from 'app/models/currency.model';
import {
  ComboGroup, DisplayedGroup,
  DisplayedProduct, DisplayedSize,
  GecoPosMenu,
  Group,
  MenuSection, MenuSyncOfPOS, MenuSyncOfSale,
  ModifierGroup, OverridePosition,
  Product,
  Size
} from 'app/models/menu.model';
import * as MenuActions from 'app/reducers/menu.actions';
import { enableMapSet } from 'immer';

export const menuFeatureKey = 'menu';

class MenuUploadsStateModel {
  menuSyncErrors: { [pipeId: string]: string };
  menuSyncLoadings: { [pipeId: string]: boolean };
}

export interface MenuState {
  currency: Currency;
  organization: PosOrganizationModel;
  menu: GecoPosMenu;
  dictionaryGroups: Map<string, DisplayedGroup>;
  originalDictionaryGroups: Map<string, Group>;
  dictionaryProducts: Map<string, DisplayedProduct>;
  originalDictionaryProducts: Map<string, Product>;
  dictionarySizes: Map<string, DisplayedSize>;
  originalDictionarySizes: Map<string, Size>;
  dictionaryModifiersGroups: Map<string, ModifierGroup>;
  originalDictionaryModifiersGroups: Map<string, ModifierGroup>;
  dictionaryComboGroup: Map<string, ComboGroup>;
  originalDictionaryComboGroup: Map<string, ComboGroup>;
  menuSections: MenuSection[];
  overridePositions: OverridePosition[];
  searchQuery: string;
  getMenusPending: boolean;
  menuSyncsOfSale: MenuSyncOfSale[];
  menuSyncOfPOS: MenuSyncOfPOS;
  uploadsState: MenuUploadsStateModel;
}

export const initialState: MenuState = {
  currency: null,
  organization: null,
  menu: null,
  dictionaryGroups: new Map<string, DisplayedGroup>(),
  originalDictionaryGroups: new Map<string, Group>(),
  dictionaryProducts: new Map<string, DisplayedProduct>(),
  originalDictionaryProducts: new Map<string, Product>(),
  dictionarySizes: new Map<string, DisplayedSize>(),
  originalDictionarySizes: new Map<string, Size>(),
  dictionaryModifiersGroups: new Map<string, ModifierGroup>(),
  originalDictionaryModifiersGroups: new Map<string, ModifierGroup>(),
  dictionaryComboGroup: new Map<string, ComboGroup>(),
  originalDictionaryComboGroup: new Map<string, ComboGroup>(),
  menuSections: [],
  overridePositions: [],
  searchQuery: '',
  getMenusPending: false,
  menuSyncsOfSale: [],
  menuSyncOfPOS: null,
  uploadsState: {
    menuSyncErrors: {},
    menuSyncLoadings: {},
  },
};

export const menuReducer = createReducer<MenuState>(initialState, (builder) => {
  enableMapSet();

  builder.addCase(MenuActions.setMenusPending, (state, action) => {
    return {...state, getMenusPending: action.payload};
  });

  builder.addCase(MenuActions.initFullMenu, (state, action) => {
    const result = Object.assign({...state}, action.payload);
    return {...result, getMenusPending: false};
  });

  builder.addCase(MenuActions.setCurrencyFromEnum, (state, action) => {
    return {
      ...state,
      currency: action.payload,
    };
  });

  builder.addCase(MenuActions.setMenuOverridePositions, (state, action) => {
    const [overridePositions, dictionaryGroups, dictionaryProducts, dictionarySizes, dictionaryModifiersGroups, dictionaryComboGroup] = action.payload;
    return {
      ...state,
      overridePositions,
      dictionaryGroups,
      dictionaryProducts,
      dictionarySizes,
      dictionaryModifiersGroups,
      dictionaryComboGroup
    };
  });

  builder.addCase(MenuActions.setSearchQuery, (state, action) => {
    let newState: MenuState = {...state, searchQuery: action.payload};
    return newState;
  });

  builder.addCase(MenuActions.setMenuSyncOfPOS, (state, action) => {
    let newState: MenuState = {...state, menuSyncOfPOS: action.payload};
    return newState;
  });

  builder.addCase(MenuActions.setMenuSyncOfSales, (state, action) => {
    let newState: MenuState = {...state, menuSyncsOfSale: action.payload};
    return newState;
  });

  builder.addCase(MenuActions.setMenuSections, (state, action) => {
    let newState: MenuState = {...state,menuSections: action.payload };
    return newState;
  })

  builder.addCase(MenuActions.setDictionaryGroupsAndOverridePositions, (state, action) => {
    const [ overridePositions ,dictionaryGroups] = action.payload;
    let newState: MenuState = {...state, overridePositions, dictionaryGroups};
    return newState;
  })

  builder.addCase(MenuActions.setDictionaryProductsAndOverridePositions, (state, action) => {
    const [ overridePositions ,dictionaryProducts] = action.payload;
    let newState: MenuState = {...state, overridePositions, dictionaryProducts};
    return newState;
  })

  builder.addCase(MenuActions.setDictionarySizesAndOverridePositions, (state, action) => {
    const [ overridePositions ,dictionarySizes] = action.payload;
    let newState: MenuState = {...state, overridePositions, dictionarySizes};
    return newState;
  })

  builder.addCase(MenuActions.setDictionaryModifierGroupsAndOverridePositions, (state, action) => {
    const [ overridePositions , dictionaryModifierGroups ] = action.payload;
    let newState: MenuState = {...state, overridePositions, dictionaryModifiersGroups: dictionaryModifierGroups};
    return newState;
  })

  builder.addCase(MenuActions.setOverridePositions, (state, action) => {
    let newState: MenuState = {...state, overridePositions: action.payload};
    return newState;
  })

  builder.addCase(MenuActions.setDictionaryGroups, (state, action) => {
    let newState: MenuState = {...state, dictionaryGroups: action.payload};
    return newState;
  })

  builder.addCase(MenuActions.setDictionaryComboGroupsAndOverridePositions, (state, action) => {
    const [ overridePositions , dictionaryComboGroup ] = action.payload;
    let newState: MenuState = {...state, overridePositions , dictionaryComboGroup};
    return newState;
  })

  builder.addCase(MenuActions.setLoadingUploadingState, (state, action) => {
    const [ pipeId, isLoading ] = action.payload;
    let loadings = state.uploadsState.menuSyncLoadings ?? {};
    loadings = {...loadings, [pipeId]: isLoading};
    let newUploadsState = {...state.uploadsState, menuSyncLoadings: loadings };

    if (isLoading && state.uploadsState.menuSyncErrors){
      let errors = state.uploadsState.menuSyncErrors;
      errors = {...errors, [pipeId]: ''};
      newUploadsState = {...newUploadsState, menuSyncErrors: errors };
    }

    let newState: MenuState = {...state, uploadsState: newUploadsState};
    return newState;
  })

  builder.addCase(MenuActions.setErrorUploadingState, (state, action) => {
    const [ pipeId, error ] = action.payload;
    let errors = state.uploadsState.menuSyncErrors ?? {};
    errors = {...errors, [pipeId]: error};
    let newState: MenuState = {...state, uploadsState: {...state.uploadsState, menuSyncErrors: errors }};
    return newState;
  })

  builder.addCase(MenuActions.clearErrorsUploadingState, (state, action) => {
    let newState: MenuState = {...state, uploadsState: {...state.uploadsState, menuSyncErrors: {} }};
    return newState;
  })

  builder.addCase(MenuActions.setSize, (state, action) => {
    let newSizes = new Map<string, DisplayedSize>();
    for(const key of state.dictionarySizes.keys()){
      newSizes.set(key, state.dictionarySizes.get(key));
    }
    newSizes.set(action.payload.externalId, action.payload);
    let newState: MenuState = {...state, dictionarySizes: newSizes};
    return newState;
  })
});
