import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/reducers/index';
import * as fromMenuReducer from 'app/reducers/menu.reducer';
import { DisplayedGroup, DisplayedProduct, GroupStructure, Product } from 'app/models/menu.model';

const selectSelf = (state: State): fromMenuReducer.MenuState => state[ fromMenuReducer.menuFeatureKey ];

export const selectMenuOrganization = createSelector(
  selectSelf,
  (state) => {
    return state.organization;
  }
);

export const selectOverridePositions = createSelector(
  selectSelf,
  (state) => {
    return state.overridePositions;
  }
);

export const selectMenuSections = createSelector(
  selectSelf,
  (state) => {
    return state.menuSections;
  }
);

export const selectMenu = createSelector(
  selectSelf,
  (state) => {
    return state.menu;
  }
);

export const selectCurrency = createSelector(
  selectSelf,
  (state) => {
    return state.currency;
  }
);

export const selectMenuPending = createSelector(
  selectSelf,
  (state) => {
    return state.getMenusPending;
  }
);

export const selectDictionarySizes = createSelector(
  selectSelf,
  (state) => {
    return state.dictionarySizes;
  }
);

export const selectOriginalDictionarySizes = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionarySizes;
  }
);

export const selectDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryGroups;
  }
);

export const selectDictionaryGroupsAndOverridePositions = createSelector(
  selectSelf,
  (state) => {
    return {dictionaryGroups: state.dictionaryGroups, overridePositions: state.overridePositions};
  }
);

export const selectOriginalDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryGroups;
  }
);

export const selectDictionaryProducts = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryProducts;
  }
);

export const selectOriginalDictionaryProducts = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryProducts;
  }
);

export const selectFilteredDictionaryProducts = createSelector(
  selectSelf,
  (state) => {
    if (!state.searchQuery) {
      return state.dictionaryProducts;
    }

    let result = new Map<string, DisplayedProduct>;

    for (let [key, product] of state.dictionaryProducts) {
      if (filterProduct(product, state.searchQuery)) {
        result.set(key, product);
      }
    }

    return result;
  }
);

export const selectFilteredDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    let result = new Map<string, DisplayedGroup>();

    for (let [key, group] of state.dictionaryGroups) {
      if (!state.searchQuery) {
        result.set(key, group);
        continue;
      } else if (group.name && group.name.toLowerCase().includes(state.searchQuery.toLowerCase())) {
        const filteredGroup = {...group, isFiltered: true};
        result.set(key, filteredGroup);
        continue;
      }

      let filteredGroup = {...group};
      filteredGroup.groupStructure = {
        order: group.groupStructure.order,
        productsInGroup: []
      } as GroupStructure;

      for (let productInGroup of group.groupStructure.productsInGroup) {
        const productInfo = state.dictionaryProducts.get(productInGroup.externalProductId);
        if (!filterProduct(productInfo, state.searchQuery)) {
          continue;
        }
        filteredGroup.groupStructure.productsInGroup.push(productInGroup);
      }

      if (!filteredGroup.groupStructure.productsInGroup.length) {
        continue;
      }

      result.set(key, filteredGroup);
    }

    return result;
  }
);

export const selectDictionaryModifierGroup = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryModifiersGroups;
  }
);

export const selectOriginalDictionaryModifierGroup = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryModifiersGroups;
  }
);

export const selectDictionaryComboGroup = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryComboGroup;
  }
);

export const selectOriginalDictionaryComboGroup = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryComboGroup;
  }
);

export const selectMenuSyncOfPOS = createSelector(
  selectSelf,
  (state) => {
    return state.menuSyncOfPOS;
  }
);

export const selectMenuSyncsOfSale = createSelector(
  selectSelf,
  (state) => {
    return state.menuSyncsOfSale;
  }
);

export const selectInfoForOverridePositions = createSelector(
  selectOverridePositions,
  selectOriginalDictionaryGroups,
  selectOriginalDictionaryProducts,
  selectOriginalDictionarySizes,
  selectOriginalDictionaryModifierGroup,
  selectOriginalDictionaryComboGroup,
  (overridePositions, originalDictionaryGroups, originalDictionaryProducts, originalDictionarySizes, originalDictionaryModifierGroup, originalDictionaryComboGroup) => {
    return {
      overridePositions,
      originalDictionaryGroups,
      originalDictionaryProducts,
      originalDictionarySizes,
      originalDictionaryModifierGroup,
      originalDictionaryComboGroup
    };
  }
);

export const selectLoadingUploadingState = createSelector(
  selectSelf,
  (state) => {
    return (pipeId: string) => state.uploadsState.menuSyncLoadings[ pipeId ];
  }
);

export const selectErrorUploadingState = createSelector(
  selectSelf,
  (state) => {
    return (pipeId: string) => state.uploadsState.menuSyncErrors[ pipeId ];
  }
);

export const selectMenuSyncsOfSaleByMenu = createSelector(
  selectSelf,
  (state) => {
    return (menuId: string) => state.menuSyncsOfSale.filter(m => m.menuId === menuId);
  }
);

const filterProduct = (product: Product, query: string): boolean => {
  let result = product.name && product.name.toLowerCase().includes(query.toLowerCase())
    || product.sku && product.sku.includes(query)
    || product.description && product.description.toLowerCase().includes(query.toLowerCase());

  return result;
}
