export interface GecoPosMenu {
  groups: Group[];
  products: Product[];
  comboGroups: ComboGroup[];
  sizes: Size[];
  modifierGroups: ModifierGroup[];
  isSynced: boolean;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface MenuSection {
  id: string;
  name: string;
  description?: string;
  menuId: string;
  isDeleted: boolean;
  parentMenuSectionId?: string;
}

export interface BatchOverridePositionsRequest {
  overridePositions: OverridePositionRequest[];
  menuSectionIds: string[];
}

export interface OverridePosition {
  id?: string;
  positionType: PositionTypeEnum;
  positionId: string;
  override: string;
  menuSectionId: string;
}

export class OverridePositionsMap {

  models: OverridePosition[] = [];
  _map: Map<PositionTypeEnum, Map<string, OverridePosition>> = new Map();

  constructor(models: OverridePosition[]) {
    this.models = models;

    Object.values(PositionTypeEnum).forEach(value => {
      this._map.set(value as unknown as PositionTypeEnum, new Map())
    })

    models.forEach(override => {
      this._map.get(override.positionType).set(override.positionId, override);
    })
  }

  getOverride(positionType: PositionTypeEnum, positionId: string): OverridePosition | undefined {
    return this._map.get(positionType).get(positionId);
  }
}

export const emptyOverride = '{}';

export interface OverrideInstruction {
  key: string,
  value: string,
  type: JsonObjectTypeEnum,
  instruction: OverrideInstructionEnum,
}

export interface OverridePositionRequest extends OverridePosition {
  instructions: OverrideInstruction[];
}

export interface CreateGroupRequest extends Group {
  menuSectionId: string;
}

export interface UploadImageRequest {
  organizationId: string;
  imageUrl?: string;
  imageBase64?: string;
  IsDeletedPicture: boolean;
}

export interface UpdateMenuSectionRequest extends CreateMenuSectionRequest{
  id: string;
}

export interface CreateMenuSectionRequest {
  name: string;
  description?: string;
  copyFromMenuSectionId?: string;
  parentMenuSectionId?: string;
  isDeleted: boolean;
  organizationId: string;
}

export interface MenuEvent {
  id: string;
  entityId: string;
  entity: MenuEventEntityEnum;
  menuSectionId: string;
  userId: string;
  userEmail: string;
  oldData: string;
  newData: string;
  createdAt: Date;
}

export enum MenuEventEntityEnum{
  Menu = 'Menu',
  Group = 'Group',
  GroupStructure = 'GroupStructure',
  Product = 'Product',
  ProductStructure = 'ProductStructure',
  ProductInGroup = 'ProductInGroup',
  Size = 'Size',
  SizeStructure = 'SizeStructure',
  ModifierGroup = 'ModifierGroup',
  ModifierGroupStructure = 'ModifierGroupStructure',
  ModifierProductStructure = 'ModifierProductStructure',
  ComboStructure = 'ComboStructure',
  ComboGroup = 'ComboGroup',
  ComboGroupStructure = 'ComboGroupStructure',
  ComboProductStructure = 'ComboProductStructure',
}

export interface Group {
  externalId: string;
  name: string;
  description: string;
  nameTranslations: NameTranslationType;
  descriptionTranslations: object;
  tags: string[];
  externalPictureUrl?: string;
  metaInfo?: string;
  groupStructure: GroupStructure;
}

export interface DisplayedGroup extends Group {
  hasReOrderingProductInGroup: boolean;
  assortmentHasChanged: boolean;
  isFiltered: boolean;
}

export interface GroupStructure {
  order: number;
  productsInGroup: ProductInGroup[];
  parentId?: string;
}

export interface ProductInGroup {
  externalProductId: string;
  order: number;
}

export interface Product {
  externalId: string;
  name: string;
  description: string;
  sku: string;
  nameTranslations: NameTranslationType;
  descriptionTranslations: object;
  tags: string[];
  weight: number;
  measure: string;
  metaInfo?: string;
  externalPictureUrl?: string;
  hideSizesImages?: boolean;
  productStructure?: ProductStructure;
  comboStructure?: ComboStructure;
}

export interface ProductStructure {
  price: number;
  type: string;
  sizes: SizeStructure[];
  modifierGroups: ModifierGroupStructure[];
}

export interface Size {
  externalId: string;
  name: string;
  nameTranslations: NameTranslationType;
}

export interface DisplayedSize extends Size {
  isChangedSize: boolean;
}

export interface SizeStructure {
  sizeId: string;
  isHidden: boolean;
  order: number;
  price: number;
  externalPictureUrl?: string;
}

export interface ModifierGroup {
  externalId: string;
  name: string;
  description: string;
  nameTranslations: NameTranslationType;
  descriptionTranslations: object;
  metaInfo: string;
}

export interface ModifierGroupStructure {
  modifierGroupId: string;
  required: boolean;
  isHidden: boolean;
  order: number;
  min: number;
  max: number;
  modifierProducts: ModifierProductStructure[];
  childModifiersHaveMinMaxRestrictions: boolean;
  hideImages?: boolean;
}

export interface ModifierProductStructure {
  modifierId: string;
  isHidden: boolean;
  order: number;
  min: number;
  max: number;
  defaultAmount: number;
}

export interface ComboStructure {
  price: number;
  type: string;
  groups: ComboGroupStructure[];
}

export interface ComboGroup {
  externalId: string;
  name: string;
  nameTranslations: NameTranslationType;
}

export interface ComboGroupStructure {
  comboGroupId: string;
  isHidden: boolean;
  order: number;
  products: ComboProductStructure[]
}

export interface ComboProductStructure {
  externalProductId: string;
  isHidden?: boolean;
  order?: number;
  sizeId?: string;
  price: number;
}

export interface DisplayedProduct extends Product {
  isAnyChanged: boolean;
  isChangedPrice: boolean;
  isChangedName: boolean;
  productStructure?: DisplayedProductStructure;
  comboStructure?: DisplayedComboStructure;
}

export interface DisplayedProductStructure extends ProductStructure {
  isChangedPrice: boolean;
  sizes: DisplayedSizeStructure[];
}

export interface DisplayedComboStructure extends ComboStructure {
  isChangedPrice: boolean;
  sizes: DisplayedSizeStructure[];
  groups: DisplayedComboGroupStructure[];
}

export interface DisplayedSizeStructure extends SizeStructure {
  isChangedPrice: boolean;
}

export interface DisplayedComboProductStructure extends ComboProductStructure {
  isChangedPrice: boolean;
  isChangedName: boolean;
  isChangedVisibility: boolean;
}

export interface DisplayedComboGroupStructure extends ComboGroupStructure {
  products: DisplayedComboProductStructure[]
}

export interface GetMenuSyncOfExternalPOSResponse {
  externalPOS: string;
  lastSyncedAt?: Date;
  pipeId: string;
}

export interface GetMenuSyncsOfSaleSystemsResponse {
  records: { [menuId: string]: MenuSyncOfSaleSystem[] };
}

export interface MenuSyncOfSaleSystem {
  saleSystem: string;
  lastSyncedAt?: Date;
  pipeId: string;
}

export interface MenuSyncOfSale {
  service: string;
  lastSyncedAt?: Date;
  pipeId: string;
  menuId: string;
}

export interface MenuSyncOfPOS {
  pos: string;
  lastSyncedAt?: Date;
  pipeId: string;
}

export interface MenuOverride {
  groupIds?: string[];
}

export interface GroupOverride {
  name?: string;
  tags?: string[];
  description?: string;
  nameTranslations?: NameTranslationType;
  descriptionTranslations?: NameTranslationType;
  externalPictureUrl?: string;
}

export interface GroupStructureOverride {
  productIds?: string[];
  parentId?: string;
}

export interface ProductOverride {
  name?: string;
  externalPictureUrl?: string;
  tags?: string[];
  weight?: number;
  description?: string;
  nameTranslations?: NameTranslationType;
  descriptionTranslations?: NameTranslationType;
  hideSizesImages?: boolean;
}

export interface ProductStructureOverride {
  price?: number;
}

export interface ModifierGroupOverride {
  name?: string;
  nameTranslations?: NameTranslationType;
}

export interface ModifierProductStructureOverride {
  isHidden?: boolean;
  order?: number;
  min?: number;
  max?: number;
  defaultAmount?: number;
}

export interface ModifierGroupStructureOverride {
  required?: boolean;
  isHidden?: boolean;
  order?: number;
  min?: number;
  max?: number;
  hideImages?: boolean;
}

export interface SizeOverride {
  name?: string;
  nameTranslations?: NameTranslationType;
}

export interface ComboGroupOverride {
  name?: string;
  nameTranslations?: NameTranslationType;
}

export interface ComboProductStructureOverride {
  isHidden?: boolean;
  order?: number;
  sizeId?: string;
  price?: number;
}

export interface SizeStructureOverride {
  price?: number;
  order?: number;
  isHidden?: boolean;
  externalPictureUrl?: string;
}

export enum PositionTypeEnum {
  Menu = 1,
  Group = 2,
  GroupStructure = 3,
  Product = 4,
  ProductStructure = 5,
  ProductInGroup = 6,
  Size = 7,
  SizeStructure = 8,
  ModifierGroup = 9,
  ModifierGroupStructure = 10,
  ModifierProductStructure = 11,
  ComboStructure = 12,
  ComboGroup = 13,
  ComboGroupStructure = 14,
  ComboProductStructure = 15,
}

export enum OverrideInstructionEnum {
  Add = 0,
  Change = 1,
  Delete = 2,
}

export enum JsonObjectTypeEnum {
  StringType = 0,
  NumberType = 1,
  BooleanType = 2,
  ArrayType = 3,
  ObjectType = 4,
}

export type NameTranslationType = { [key: string]: string};

export interface MenuEventsFilterRequest {
  entityId?: string;
  entity?: MenuEventEntityEnum;
  userId?: string;
  menuId?: string;
  menuSectionId?: string;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  pageSize: number;
  pageIndex: number;
}

export interface MenuEventsQueryParams extends MenuEventsFilterRequest {
}

export interface MenuEventsFormFilter extends MenuEventsFilterRequest {
}
